body {
  max-width: 90%;
  margin: auto;
  --toastify-toast-width: 400px;
}

Form.rjsf {
  /* dynamically resize form and error sections */
  .panel-danger.errors {
    max-height: 15vh;
    overflow-y: auto;
    font-size: small;
  }
  .form-group.field.field-object fieldset#root{
    max-height: 80vh;
    overflow-y: auto;
  }
  .panel-danger.errors + .form-group.field.field-object fieldset#root{
    max-height: 65vh;
  }
  
  /* indent and border-left for nested schemas */
  .form-group.field.field-object fieldset:not(#root){
    padding-left: 8px;
    border-left: 2px solid rgba(0, 0, 255, .25);
    border-radius: 5px;

    &:hover {
        border-color: rgba(0, 0, 255, .5);
    }
  }
}

div.form-group.field.field-integer {
  overflow: visible;
  min-width: 1000px;
  max-width: 80%;
}

div.form-group.field.field-number {
  max-width: 80%;
}

div.form-group.field.field-control {
  max-width: 80%;
}

div.col.col-lg-2 {
  min-width: 25%;
  max-width: 25%;
}

p.col-xs-offset-9 {
  box-sizing: border-box;
  margin: 5px;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 25%;
}

div {
  margin: 5px;
}

.Toastify__spinner {
  margin: 0px;
}